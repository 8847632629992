#portfolio {
  #about-me {
    position: relative;
    .row {
      @extend %page-padding;
    }
    .img-wrap {
      text-align: center;
      .img {
        // margin-top: 10px;
        overflow: hidden;
        display: inline-block;
        width: 220px;
        height: 220px;
        border-radius: 50%;
        box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.8), 0 5px 5px 0 rgba(0, 0, 0, 0.7);
        outline-style: solid;
        outline-width: 2px;
        outline-color: rgba(31, 30, 30, 0.7);
        img {
          width: 100%;
        }
      }
    }
    .text-wrap {
      color: $text-light;
      .username {
        color: $very-light
      }
      .description {
        margin: 10px 0;
        font-size: 0.95rem;
        a {
          color: $very-light;
          border-bottom: 1px dashed;
          &:hover  {
              border-bottom-style: solid;
              text-decoration: none;
          }
        }
      }
      @media (max-width: 767px) {
        margin-top: 15px;
        text-align: center;
      }
      #icons li {
        i {
          width: 50px;
          height: 50px;
          color: $text-medium;
          background-color: $very-dark;
          line-height: 50px;
          font-size: 38px;
        }
        i:hover {
          // color: $very-light;  # see social.html
          background-color: $very-dark;
        }
      }
    }
    .bg {
      content: "";
      z-index: -1;
      position: absolute;
      width: 100%;
      height: 160%;
      top: -60px;
      background-color: $very-dark;
    }
  }
  #career {
    @extend %page-padding;
    margin-bottom: 55px;
    margin-top: 55px;
    background-color: $dark;
    .title {
      margin-top: 15px;
      color: $medium;
      font-size: 25px;
      font-weight: 600;
    }
    .exper {
      padding-top: 35px;
      .company {
        a.name {
          font-size: 1.25rem;
          font-weight: 600;
          color: $very-light;
          display: inline-block;
          border-bottom: 0.75px dashed;
          &:hover  {
              border-bottom-style: solid;
              text-decoration: none;
          }
        }
        img {
          position: relative;
          top: 17px;
          height: 50px;
          border-radius: 25%;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
          margin-left: 3%;
          left: -22px;
        }
      }
      ul.exper-list {
        margin-bottom: 5px;
        margin-left: 3%;
        border-left: 5px solid $light;
        list-style-type: none;
        li {
          animation-name: fadein;
          animation-duration: 1s;
          animation-timing-function: ease-in-out;
          -webkit-animation-name: fadein;
          -webkit-animation-duration: 1s;
          -webkit-animation-timing-function: ease-in-out;
        }
        li.meta {
          position: relative;
          padding: 15px 0 15px 30px;
          p {
            font-weight: 600;
            line-height: 1rem;
          }
          .date {
            font-size: 0.75rem;
            color: $text-medium;
          }
          .experience {
            font-size: 1rem;
            color: $text-light;
          }
          .description {
            font-size: 0.75rem;
            font-weight: 400;
            color: $text-dark;
          }
        }
        li.meta::before {
          content: "";
          position: absolute;
          width: 15px;
          height: 15px;
          top: 42%;
          left: -10px;
          background-color: $light;
          border-radius: 50%;
        }
      }
      @keyframes fadein {
        from {
          transform: translateY(-10%);
          opacity: 0;
        }
        to {
          transform: translateY(0%);
          opacity: 1;
        }
      }
    }
    .skills {
      padding-top: 35px;
      ul.skill-list {
        margin-top: 20px;
        margin-left: 3%;
        list-style-type: none;
        li.skill {
          margin: 18px auto;
          p {
            font-weight: 600;
            line-height: 1rem;
          }
          .name {
            font-size: 1rem;
            color: $text-light;
          }
          .details {
            font-size: 0.75rem;
            font-weight: 400;
            color: $text-dark;
          }
          .progress {
            position: relative;
            overflow: hidden;
            width: 100%;
            height: 18px;
            background-color: $very-dark;
            border-radius: 5px;
            animation-name: progressAnimation;
            animation-duration: 1s;
            animation-timing-function: ease-in-out;
            -webkit-animation-name: progressAnimation;
            -webkit-animation-duration: 1s;
            -webkit-animation-timing-function: ease-in-out;
          }
          .progress-bar {
            position: absolute;
            height: 18px;
            background-color: $medium;
          }
          .progress-value {
            position: absolute;
            right: 5px;
            color: $very-dark;
            font-size: 0.6rem;
            font-weight: 600;
            line-height: 1.1rem;
          }
        }
      }
      @keyframes progressAnimation {
        from {
          width: 40%;
        }
        to {
          width: 100%;
        }
      }
    }

    ul.interests-list {
      margin-top: 20px;
      margin-left: 3%;
      list-style-type: none;
      list-style-position: inside;
      li.interest {
        margin: 8px auto;
        padding-left: 5px;
        font-size: 0.85rem;
        font-weight: 600;
        color: $text-medium;
      }
      li.interest::before {
        content: "•";
        font-size: 200%;
        color: $text-medium;
        padding-right: 5px;
        vertical-align: text-top;
      }
    }

    ul.contributions-list {
      margin-top: 20px;
      margin-left: 3%;
      list-style-type: none;
      list-style-position: inside;
      li.contribution {
        border: none;
        color: $text-medium;
        padding: 4px 4px;
        font-size: 0.65rem;
        font-weight: 500;
        text-align: center;
        display: inline-block;
        margin: 2px;
        border-radius: 5px;
        border-color: $text-dark;
        border-style: solid;
        border-width: 0.10px;
        &:hover  {
          border-color: $text-light;
          color: $text-light;
        }
        a {
          color: inherit;
          font-size: 0.65rem;
          font-weight: 500;
          &:hover  {
            color: $text-light;
          }
        }
      }
    }

    ul.trainings-list {
      margin-top: 20px;
      margin-left: 3%;
      list-style-type: none;
      list-style-position: inside;
      li.training {
        border: none;
        color: $text-medium;
        padding: 4px 4px;
        font-size: 0.65rem;
        font-weight: 500;
        text-align: center;
        display: inline-block;
        margin: 2px;
        border-radius: 5px;
        border-color: $text-dark;
        border-style: solid;
        border-width: 0.10px;
        &:hover  {
          border-color: $text-light;
          color: $text-light;
        }
        a {
          color: inherit;
          font-size: 0.65rem;
          font-weight: 500;
          &:hover  {
            color: $text-light;
          }
        }
      }
    }
  }
}
