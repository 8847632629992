// https://coolors.co/palette/22223b-4a4e69-9a8c98-c9ada7-f2e9e4
// $very-dark: #22223B;
// $dark: #4A4E69;
// $medium: #9A8C98;
// $light: #C9ADA7;
// $very-light: #F2E9E4;

$very-dark: #21211E;
$dark: #262623;
$medium: #C99B6C;
$very-light: #A1ECDC;
$light: #547a82; 

$text-light: #fdfefd;
$text-medium: #c7c7c7;
$text-dark: #969696;

%page-padding {
    padding : 0px 15%!important;
    @media (max-width: 991px) {
        padding: 0px 10%!important;
    }
    @media (max-width: 576px) {
        padding: 0px 15px!important;
    }
}
%posts-padding {
    @media (max-width: 991px) {
        padding: 0px 10%!important;
    }
    @media (max-width: 576px) {
        padding: 0px!important;
    }
}
%page-nav-btn {
    right : 5%;
    @media (max-width: 991px) {
        right : 3%;
    }
}
