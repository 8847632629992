// Definition of tag style in post
#post{
    h1, h2, h3, h4, h5, h6{
        margin : 15px 0 10px 0;
    }
    ol, ul {
        * { font-size: 16px!important; }
    }
    ul {
        list-style-position: outside;
        list-style-type: none;
        margin-top:2px;
        padding-left: 10px;
        li {
            position: relative;
            margin: 5px auto;
            padding-left:30px!important;
        }
    }
    ul li:before{
        content: "";
        position: absolute;
        top: 8px;
        left: 10px;
        width: 7px;
        height: 7px;
        background-color: $light;
        border-radius: 50%;
    }
    ol {
        list-style-type: decimal;
        margin-left: 10px;
        padding-left: 30px;
        li {
            position: relative;
            margin: 5px auto;
        }
    }
    a {
        color: #4F86C6;
    }
    img {
        max-width : 96%;
        margin: 2%;
        box-shadow: 0 0px 6px 1px rgba(0,0,0,0.1);
        &.width-30{
            max-width: 30%;
            margin: 2% 35%;
        }
        &.width-40{
            max-width: 40%;
            margin: 2% 30%;
        }
        &.width-50{
            max-width: 50%;
            margin: 2% 25%;
        }
        &.width-60{
            max-width: 60%;
            margin: 2% 20%;
        }
        &.width-70{
            max-width: 70%;
            margin: 2% 15%;
        }
        &.width-80{
            max-width: 80%;
            margin: 2% 10%;
        }
    }
    table {
        width: 100%;
        margin : 10px auto;
        word-wrap: break-word;
        border-collapse:collapse;
        padding: auto;
        thead{
            background-color: #ced3d638;
        }
        td, th {
            padding: 0.5em 1em;
            border: 1px solid #ddd;
            font-size: 16px;
        }
    }
    blockquote {
        background: #ced3d638;
        color: rgba(0,0,0,0.5);
        margin: 10px auto;
        padding: 8px 20px;
        * { font-size: 16px; }
    }
    .preview{
        display: none;
    }
}