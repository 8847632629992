footer{
    @extend %page-padding;
    margin-top: 10px;
    .footer {
        background-color: $dark;
        text-align: center;
        font-size: 0.75rem;
        padding-top: 30px!important;
        padding-bottom: 30px;
        border-top: 1px solid $text-dark;
        color: $text-dark;
        a {
            font-size: 0.75rem;
            font-weight: 600;
            color: $very-dark!important;
        }
    }
}