*{
	-webkit-text-size-adjust:none;
	font-family: 'Spoqa Han Sans', 'Spoqa Han Sans JP', 'Sans-serif';
	box-sizing: border-box;
}
html, body {
	height:100%;
}
body {
	-webkit-user-select:text;
	-webkit-touch-callout:none;
	-webkit-tap-highlight-color:rgba(0,0,0,0);
	background-color: #262623;
}

/* Responsive sizes */
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;

.resume-button {
	color: $text-light;
	font-size: 24px;
	position: fixed;
	top: 10px;
	right: 20px;
	cursor: pointer;
	line-height: 1.25em;
	font-weight: 900;
	padding: 8px;
	background-color: $light;
	z-index: 100;
	border-radius: 20%;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	@media (min-width: $screen-sm-min) {
	  top: 20px;
	  right: 40px;
	}
}
.resume-button:hover {
	background-color: $medium
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
	word-break: keep-all;
	font-size: 1.05rem;
	line-height: 1.5em;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, main {
	display: block;
}
html, body {
	color: $medium;
	height: 100%;
}
h1, h2, h3, h4, h5, h6{
	font-weight: 600;
}
h1 {
	font-size : 1.8rem;
}
h2 {
	font-size : 1.6rem;
}
h3 {
	font-size : 1.4rem;
}
h4 {
	font-size : 1.3rem;
}
h5 {
	font-size : 1.2rem;
}
h6 {
	font-size : 1.05rem;
}
p{
	margin: 5px 0;
}
@media (max-width: 576px){
	h1 {
		font-size : 1.6rem;
	}
	h2 {
		font-size : 1.4rem;
	}
	h3 {
		font-size : 1.3rem;
	}
	h4 {
		font-size : 1.2rem;
	}
	h5 {
		font-size : 1.05rem;
	}
	h6 {
		font-size : 1rem;
	}
	p{
		font-size : 0.9rem;
	}
}
ol, ul {
	li { font-size: 15px; }
}
a:link, a:visited, a:active, a:hover {
	text-decoration: none; 
	color: $very-light;
}
.container{
	padding-top: 30px;
	padding-bottom: 30px;
}
.row{
	margin : auto 0px;
}
.display-none{
	display: none;
}
.highlighter-rouge {
    font-size: 0.9rem;
    color: $very-light;
    background-color: $light;
    padding: .1em .5em;
    border-radius: 3px;
}
.tag {
	background-color: $medium;
	display: inline-block;
	margin-bottom: 10px;
	margin-right : 3px;
	padding: 1px 15px 0px;
    border-radius: 20px;
    color: #fff;
	font-weight: 600;
	font-size: 0.85rem;
	line-height: 35px;
	letter-spacing: -0.03rem;
}
.tag:hover {
	background-color: $very-dark;
}
#icons{
	margin-top: 25px;
	li { 
		display: inline-block; 
		margin-bottom: 10px;
	}
	i{
		width: 40px;
		height: 40px;
		background-color: $very-dark;
		color: $very-light;
		border-radius: 50%;
		font-size : 1.3rem;
		line-height: 42px;
		margin-right : 10px;
		text-align: center;
	}
	i:hover {
		background-color: $medium;
		color : $medium;
	}
}
#error404{
	@extend %page-padding;
	margin-top : 200px;
	text-align: center;

	.return-btn {
		color : rgba(0,0,0,0.7);
		p {
			display: inline-block;
			padding: 10px 30px;
			margin-top: 30px;
			border-radius: 15px;
			font-weight: 600;
			background-color: rgba(0,0,0,0.07);
		}
		}
	.return-btn:hover{ 
		p{
			background-color: $medium;
			color : #fff;	
		}
	}

}
a.btn  {
		color: $very-light;
		text-decoration: underline;
		padding-left: 0.2rem;
		padding-right: 0.2rem;
		&:hover {
				color: $very-light;
		}
}
