#project {
  position: relative;
  overflow: hidden;
  .project-wrap {
    @extend %page-padding;
    padding-top: 50px!important;
    margin-bottom: 55px;
    p.title {
      padding-left: 10px;
      color: $very-light;
      font-size: 25px;
      font-weight: 600;
    }
  }
  .card-container {
    overflow: hidden;
    column-count: 3;
    -webkit-column-count: 3;
    -moz-column-count: 3;
    @media (max-width: 1200px) {
      column-count: 2;
      -webkit-column-count: 2;
      -moz-column-count: 2;
    }
    @media (max-width: 576px) {
      column-count: 1;
      -webkit-column-count: 1;
      -moz-column-count: 1;
    }
    .card-wrapper {
      padding: 8px 10px;
      margin: 10px auto;
      display: inline-block;
      width: 100%;
      .card {
        -webkit-transition-duration: 0.8s;
        -moz-transition-duration: 0.8s;
        -ms-transition-duration: 0.8s;
        -o-transition-duration: 0.8s;
        transition-duration: 0.8s;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);

        overflow: hidden;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: rgba($dark, 0.15) 0px 1px 5px;
        .img-wrap {
          position: relative;
          overflow: hidden;
          height: 200px;
          @media (max-width: 576px) {
            display: none;
          }
          img {
            width: 100%;
            height: 200px;
            object-fit: cover;
          }
        }
        .img-wrap:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: $dark;
        }
        .text-wrap {
          padding: 20px 25px;
          // height: 180px;
          .title {
            color: $very-light;
            font-weight: 600;
          }
          .date {
            color: rgba(0, 0, 0, 0.5);
            font-size: 0.75rem;
            margin: 0;
          }
          .meta,
          .meta span {
            color: rgba(0, 0, 0, 0.5);
            font-size: 0.75rem;
            margin: 3px 0 10px;
          }
          .description {
            margin: 15px auto;
            font-size: 0.9rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            overflow: hidden;
          }
        }
        .icons{
          margin-bottom: 20px;
          padding-right: 25px;
            float: right;
            a {
              margin-left: 8px;
              text-align: center;
              i {
                width: 30px;
                height: 30px;
                background-color: rgba(0, 0, 0, 0.07);
                border-radius: 50%;
                font-size: 0.8rem;
                line-height: 1.8rem;
              }
            }
            a.modal-btn i {
              background-color: $medium;
              color: #fff;
              font-size: 0.75rem;
            }
        }
      }
      .card:hover{
        -webkit-transform: scale(1.03);
        -moz-transform: scale(1.03);
        -ms-transform: scale(1.03);
        -o-transform: scale(1.03);
        transform: scale(1.03);
        box-shadow: rgba($dark, 0.15) 0px 5px 15px;
        .icons{
          a {
            i {
              -webkit-transition-duration: 0.5s;
              -moz-transition-duration: 0.5s;
              -ms-transition-duration: 0.5s;
              -o-transition-duration: 0.5s;
              transition-duration: 0.5s;
              background-color: $very-dark;
              color: #fff;
            }
          }
          a:hover {
            i {
              background-color: $medium;
              color: #fff;
            }
          }
        }
      }
    }
  }
  .bg {
    content: "";
    z-index: -99;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.05);
  }
}